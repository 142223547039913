import { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

import useAuth from "../../common/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";

import useScroll from "../../common/hooks/useScroll";
import useUserControl from "../../common/hooks/useUserControl";
import useModal from "../../common/hooks/useModal";

import {
  fetchMyFeedStatus,
  fetchFeed,
  fetchInitFeeds,
} from "../../features/feedList/feedListThunks";

import {
  selectFeed,
  selectFeedLoading,
  selectFeedNextPageIds,
  selectSelectedTab,
  setSelectedTab,
} from "../../features/feedList/feedListSlice";

import {
  FeedItemList,
  ShowTranslation,
  ConvertingTab,
  PullToRefresh,
  BannerNotice,
  Loading,
  LoadingMoreIndicator,
  ScrollToTopButton,
  ThemeToggle,
  Icon,
} from "../../components";

import { UserControlModal, MyFeedBanner } from "../../systems";

import getTranslation from "../../common/utils/getTranslation";

import { IFeedItem } from "../../common/types/commonTypes";

import colors from "../../styles/colors";

import { delay } from "../../common/utils/globalUtils";

import {
  Container,
  Header,
  WriteFeedButton,
  PlusIcon,
  StickyHeader,
  LoadingMoreIndicatorWrapper,
  WriteFeedNoticeButton,
  HeaderButtonWrapper,
  RedDot,
} from "./styles";
import useSettings from "../../common/hooks/useSettings";
import useModeColors from "../../common/hooks/useModeColors";

import { MessageFromAppCallbacks } from "../../common/utils/ReactNativeWebview/appEventListenerService/types";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import ProfileExchangeItemBanner from "../../components/ProfileExchangeItemBanner";
import { selectPromotion } from "../../features/promotion/promotionSlice";
import { selectUserItemCounts } from "../../features/account/accountSlice";
import { selectABTestData } from "../../features/abTestData/abTestDataSlice";

const MY = "MY";
const LATEST = "LATEST";
const RECOMMEND = "RECOMMEND";

const MainFeed = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { displayMode, setDisplayMode } = useSettings();
  const modeColors = useModeColors();

  const { userData } = useAuth();

  const { getUserControlType } = useUserControl();

  const { isReachedBottom, scrollToTop } = useScroll({
    throttleValue: 100,
  });

  const selectedTab = useAppSelector(selectSelectedTab);
  const feeds = useAppSelector(selectFeed);
  const bannerNotice = useAppSelector(selectFeed).notice;
  const loading = useAppSelector(selectFeedLoading);
  const nextPageIds = useAppSelector(selectFeedNextPageIds);
  const promotionStatus = useAppSelector(selectPromotion);
  const profileExchangeItemCount =
    useAppSelector(selectUserItemCounts)["PROFILE_EXCHANGE"];
  const profileExchangeNudgeAbTestGroupValue =
    useAppSelector(selectABTestData).serverAbTest.find(
      groupData => groupData.key === "ab_profile_exchange_nudge_KRJP"
    )?.value ?? "A";

  const [isNewAlarm, setIsNewAlarm] = useState(false);

  const {
    isVisible: isUserControlModalVisible,
    showModal: showUserControlModal,
    closeModal: closeUserControlModal,
  } = useModal();

  const getMyFeed = async (fetchType: "REFRESH" | "MORE") => {
    if (fetchType === "MORE" && nextPageIds.my === 0) {
      return;
    }
    await dispatch(
      fetchFeed({
        fetchType,
        page: nextPageIds.my,
        category: "my",
      })
    );
  };

  const getLatestFeed = async (fetchType: "REFRESH" | "MORE") => {
    if (fetchType === "MORE" && nextPageIds.latest === 0) {
      return;
    }
    await dispatch(
      fetchFeed({
        fetchType,
        page: nextPageIds.latest,
        category: "latest",
      })
    );
  };

  const getRecommendFeed = async (fetchType: "REFRESH" | "MORE") => {
    if (fetchType === "MORE" && nextPageIds.recommend === 0) {
      return;
    }
    await dispatch(
      fetchFeed({
        fetchType,
        page: nextPageIds.recommend,
        category: "recommend",
      })
    );
  };

  const getNoticeFeed = async () => {
    await dispatch(
      fetchFeed({
        fetchType: "REFRESH",
        page: 0,
        category: "notice",
      })
    );
  };

  const onRefreshPage = async (): Promise<void> => {
    if (selectedTab === MY) {
      await getMyFeed("REFRESH");
      await dispatch(fetchMyFeedStatus({ uuid: userData.uuid }));
    }

    if (selectedTab === LATEST) {
      await getLatestFeed("REFRESH");
    }

    if (selectedTab === RECOMMEND) {
      await getRecommendFeed("REFRESH");
    }

    getNoticeFeed();
    return;
  };

  const navigateToWrite = () => {
    navigate("/write", { replace: false });
  };

  const onClickWriteButton = () => {
    if (
      getUserControlType() === "PAUSE" ||
      getUserControlType() === "WARNING" ||
      getUserControlType() === "FIRST_WARNING"
    ) {
      showUserControlModal();
      return;
    }
    navigateToWrite();
  };

  const onCloseUserControlModal = () => {
    if (getUserControlType() === "PAUSE") {
      closeUserControlModal();
      return;
    }
    closeUserControlModal();
    navigateToWrite();
  };

  const navigateToFeedReportDetail = (feed: IFeedItem) => {
    navigate(`/feed_report_detail/${feed.feedpost.feedpost_id}`, {
      state: {
        prevpath: pathname,
      },
    });
  };

  const navigateToFeedDetail = (feed: IFeedItem) => {
    navigate(`/feed_detail/${feed.feedpost.feedpost_id}`, {
      state: {
        prevpath: pathname,
        access: selectedTab,
      },
    });
  };

  const onClickAlarm = () => {
    ReactNativeWebview.postMessageToApp({
      type: "NAVIGATE_TO_INTEGRATED_ALARM",
    });
  };

  const onClickRecommendTab = () => {
    dispatch(setSelectedTab(RECOMMEND));

    ReactNativeWebview.postMessageToApp({
      type: "LOG_FOCUS_FEED_TAB",
      data: {
        access: "RECOMMEND",
      },
    });
  };

  const onClickMyTab = () => {
    dispatch(setSelectedTab(MY));
    ReactNativeWebview.postMessageToApp({
      type: "LOG_FOCUS_FEED_TAB",
      data: {
        access: "MY",
      },
    });
  };

  const onClickLatestTab = () => {
    dispatch(setSelectedTab(LATEST));
    ReactNativeWebview.postMessageToApp({
      type: "LOG_FOCUS_FEED_TAB",
      data: {
        access: "PUBLIC",
      },
    });
  };

  const onClickProfileExchangeBanner = () => {
    ReactNativeWebview.postMessageToApp({
      type: "SHOW_PROFILE_EXCHANGE_DISCOUNT_NUDGE_MODAL",
    });
  };

  const fetchInitialFeeds = async () => {
    await delay(500);
    dispatch(fetchInitFeeds());
  };

  useEffect(() => {
    if (userData.uuid) {
      dispatch(fetchMyFeedStatus({ uuid: userData.uuid }));
    }
  }, [userData.uuid, dispatch]);

  useEffect(() => {
    scrollToTop();
  }, [selectedTab]);

  useEffect(() => {
    if (isReachedBottom && loading === "NONE" && location.pathname === "/") {
      if (selectedTab === MY) {
        getMyFeed("MORE");
      }

      if (selectedTab === LATEST) {
        getLatestFeed("MORE");
      }

      if (selectedTab === RECOMMEND) {
        getRecommendFeed("MORE");
      }
    }
  }, [isReachedBottom, loading]);

  useEffect(() => {
    fetchInitialFeeds();
  }, []);

  useEffect(() => {
    //data 에 타입을 따로 지정해줘야하는 걸 안하고 싶어서 바로 inline 으로 하고싶었지만 결국엔 reference 떼문에 안되네요
    const handleStoreNewAlarmStatus: MessageFromAppCallbacks["STORE_NEW_ALARM_STATUS"] =
      data => {
        setIsNewAlarm(data.newAlarm);
      };

    ReactNativeWebview.addAppEventListener(
      "STORE_NEW_ALARM_STATUS",
      handleStoreNewAlarmStatus
    );

    return () => {
      ReactNativeWebview.removeAppEventListener(
        "STORE_NEW_ALARM_STATUS",
        handleStoreNewAlarmStatus
      );
    };
  }, []);

  useEffect(() => {
    ReactNativeWebview.postMessageToApp({
      type: "GET_PROMOTION_STATUS",
      data: [
        {
          promotionKey: "profile_exchange",
        },
        {
          promotionKey: "profile_exchange_3_discount",
        },
      ],
    });

    ReactNativeWebview.postMessageToApp({
      type: "GET_USER_INVENTORY",
      data: ["PROFILE_EXCHANGE", "PROFILE_EXCHANGE_3"],
    });

    ReactNativeWebview.postMessageToApp({
      type: "GET_SERVER_AB_TEST_GROUP",
    });
  }, []);

  const renderFeedList = () => {
    if (selectedTab === LATEST) {
      return (
        <FeedItemList
          showUserControlModal={showUserControlModal}
          listType={LATEST}
          data={feeds.latest}
          subtractHeight={150}
          navigateToFeedDetail={navigateToFeedDetail}
          navigateToFeedReportDetail={navigateToFeedReportDetail}
        />
      );
    }

    if (selectedTab === RECOMMEND) {
      return (
        <FeedItemList
          showUserControlModal={showUserControlModal}
          listType={RECOMMEND}
          data={bannerNotice.length > 0 ?[bannerNotice[0], ...feeds.recommend] : feeds.recommend }
          subtractHeight={150}
          navigateToFeedDetail={navigateToFeedDetail}
          navigateToFeedReportDetail={navigateToFeedReportDetail}
        />
      );
    }

    return (
      <FeedItemList
        showUserControlModal={showUserControlModal}
        listType={MY}
        data={feeds.my}
        subtractHeight={150}
        navigateToFeedDetail={navigateToFeedDetail}
        navigateToFeedReportDetail={navigateToFeedReportDetail}
      />
    );
  };

  if (loading === "LOADING") {
    return <Loading />;
  }

  return (
    <Container>
      <UserControlModal
        isVisible={isUserControlModalVisible}
        onClose={onCloseUserControlModal}
      />
      <Outlet />
      <div style={location.pathname === "/" ? {} : { display: "none" }}>
        <StickyHeader>
          <Header>
            <ShowTranslation size={19} weight={"b"} color={modeColors.text_01}>
              {getTranslation("MainFeed.headerTitle")}
            </ShowTranslation>

            {/* ab test */}
            {/* ab test */}

            <HeaderButtonWrapper>
              <ThemeToggle />
              <Icon
                name={"ic_bell_line_regular"}
                size={22}
                onClick={onClickAlarm}
              />

              <RedDot isVisible={isNewAlarm} />
            </HeaderButtonWrapper>
          </Header>

          <ConvertingTab
            tabState={selectedTab}
            leftTab={getTranslation("MainFeed.latest") as string}
            middleTab={getTranslation("MainFeed.recommend") as string}
            rightTab={getTranslation("MainFeed.my") as string}
            leftTabKey={LATEST}
            middleTabKey={RECOMMEND}
            rightTabKey={MY}
            onLeftTab={onClickLatestTab}
            onMiddleTab={onClickRecommendTab}
            onRightTab={onClickMyTab}
          />
        </StickyHeader>

        {selectedTab !== MY ? (
          <BannerNotice
          />
        ) : null}

        {selectedTab === "LATEST" &&
        profileExchangeNudgeAbTestGroupValue === "B" ? (
          <ProfileExchangeItemBanner
            onPressBanner={onClickProfileExchangeBanner}
            profileExchangeItemCount={profileExchangeItemCount ?? 0}
            freePromotionData={promotionStatus.profile_exchange}
            discountPromotionData={promotionStatus.profile_exchange_3_discount}
          />
        ) : null}

        <PullToRefresh onRefresh={onRefreshPage}>
          <>
            {selectedTab === MY ? <MyFeedBanner /> : null}
            {renderFeedList()}
          </>
        </PullToRefresh>

        <LoadingMoreIndicatorWrapper>
          <LoadingMoreIndicator isLoadingMore={loading === "LOADING_MORE"} />
        </LoadingMoreIndicatorWrapper>

        {userData.is_manager && (
          <WriteFeedNoticeButton to="/write_notice">
            <PlusIcon />
            공지 글쓰기
          </WriteFeedNoticeButton>
        )}

        <ScrollToTopButton />
        <WriteFeedButton onClick={onClickWriteButton}>
          <PlusIcon />
          <ShowTranslation size={15} weight={"b"} color={colors.White}>
            {getTranslation("MainFeed.write")}
          </ShowTranslation>
        </WriteFeedButton>
      </div>
    </Container>
  );
};

export default MainFeed;
