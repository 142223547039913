import { useState, useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import blueBanner from "../../assets/images/Banner_FeedNotice_49@3x.png";


import {
  BannerContainer,
  IndexIndicator,
  BannerImage,
  BannerTextWrapper,
  BannerWrapper,
} from "./style";
import colors from "../../styles/colors";

import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

import { ShowTranslation } from "..";

import getTranslation from "../../common/utils/getTranslation";
import useAuth from "../../common/hooks/useAuth";


const BannerNotice = () => {
  const { userData } = useAuth();

  const getFirstBannerUrl = () => {
    if (userData && "country" in userData) {
      if (userData.country === "KR") {
        return "https://bit.ly/4fBgsBl";
      }

      if (userData.country === "JP") {
        return "https://bit.ly/4hWdM2R";
      }

      return "http://connectingapp.co.kr/";
    }
    return "http://connectingapp.co.kr/";
  };

  const bannerNotifications = [
    {
      image: blueBanner,
      text: `${getTranslation("BannerNotice.shareAnythingBanner")}🥰`,
      textColor: colors.Gray900,
      onClick: () =>
        ReactNativeWebview.postMessageToApp({
          type: "OPEN_HYPERLINK",
          data: {
            url: getFirstBannerUrl(),
          },
        }),
    },
    {
      image: blueBanner,
      text: getTranslation("BannerNotice.feedRuleBanner"),
      textColor: colors.Gray900,
      onClick: () =>
        ReactNativeWebview.postMessageToApp({
          type: "NAVIGATE_TO_FEED_RULE_WEBVIEW",
        }),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(1);

  const [bannerList, setBannerList] = useState<Array<any>>(bannerNotifications);


  const onChange = (currentIndex: number) => {
    setCurrentIndex(currentIndex + 1);
  };

  const renderBanners = () => {
    return bannerList.map((banner, index) => {
        const StaticBannerNoticeProps = {
          key: `banner-id-${index}`,  
          title: banner.text,
          bannerImage: banner.image,
          textColor: banner.textColor,
          onClick: banner.onClick,
        };
        return <StaticBannerNotice {...StaticBannerNoticeProps} />;
    });
  };

  return (
    <BannerContainer>
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        dynamicHeight={true}
        showStatus={false}
        onChange={onChange}
      >
        {renderBanners()}
      </Carousel>
      <IndexIndicator>
        <ShowTranslation
          style={{ marginRight: 3 }}
          weight={"r"}
          size={10}
          color={colors.White}
        >
          {currentIndex}
        </ShowTranslation>
        <ShowTranslation
          weight={"r"}
          size={10}
          color={"rgba(255, 255, 255, 0.7)"}
        >
          {`/ ${bannerList.length}`}
        </ShowTranslation>
      </IndexIndicator>
    </BannerContainer>
  );
};

export default BannerNotice;

const StaticBannerNotice = ({
  title,
  bannerImage,
  textColor,
  onClick,
}: {
  title: string;
  bannerImage: string;
  textColor: string;
  onClick: () => void;
}) => {
  const bannerTextRef = useRef<HTMLDivElement>(null);
  const [isTruncate, setIsTruncate] = useState(false);

  useEffect(() => {
    if (bannerTextRef.current) {
      const height = bannerTextRef.current.offsetHeight;
      const lines = height / 17;

      if (lines > 1) {
        setIsTruncate(true);
      }
    }
  }, []);

  return (
    <BannerWrapper key={`banner-id-${title}`} onClick={onClick}>
      <BannerImage src={bannerImage} />
      <BannerTextWrapper>
        <ShowTranslation
          ref={bannerTextRef}
          style={{
            width: "100%",
            maxHeight: 34,
            alignItems: "center",
            textAlign: "start",
            lineBreak: "anywhere",
            lineHeight: "17px",
          }}
          size={14}
          weight={"b"}
          color={textColor}
          numberOfLines={isTruncate ? 2 : 0}
        >
          {title}
        </ShowTranslation>
      </BannerTextWrapper>
    </BannerWrapper>
  );
};


